import styled from '@emotion/styled/macro';
import { LoaderSizes } from 'src/components/atoms/Loader/types';
import { loaderTheme } from 'src/theme/tokens/components/loader';

const { variants, strokeWidth, strokeColor } = loaderTheme;

export const Loader = styled.svg<{ size: LoaderSizes }>`
  animation: spin ${({ theme }) => theme.transitions.duration.slower} linear infinite;
  height: ${({ size }) => variants[size].height};
  transform-origin: center center;
  width: ${({ size }) => variants[size].width};

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Circle = styled.circle<{ hasPrimaryColor?: boolean; size?: string }>`
  fill: none;
  stroke-width: ${strokeWidth};
  stroke: ${({ hasPrimaryColor }) =>
    hasPrimaryColor ? strokeColor.onPrimary : strokeColor.default};
  stroke-linecap: round;
  stroke-dasharray: 260;
  stroke-dashoffset: 160;
`;
