import styled from '@emotion/styled/macro';
import Text from 'src/components/atoms/typography/Text';
import { semantical } from 'src/theme/tokens/semantical';
import * as T from './types';

const footerTheme = {
  background: semantical.colors.background.neutral.strongest,
  copyright: semantical.colors.text.neutral.weakest,
} as const;

export const Footer = styled.footer<T.FooterTypes>`
  background-color: ${footerTheme.background};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.m}`};
  position: relative;
  margin-top: auto;
  ${({ hasStickyBanner }) => hasStickyBanner && ' top: -70px'};

  ${({ theme }) => theme.breakpoints.upMedium} {
    padding: ${({ theme }) => `${theme.spacing.l} ${theme.spacing['4xl']}`};
    ${({ hasStickyBanner }) => hasStickyBanner && 'top: -72px'};
  }
`;

export const FooterLinkList = styled.ul`
  list-style: none;
  padding-left: 0;
  max-width: 1043px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.upMedium} {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }
`;

export const FooterLinkListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.m};

    ${({ theme }) => theme.breakpoints.upMedium} {
      margin: 0;
    }
  }
`;

export const FooterCopyright = styled(Text)`
  color: ${footerTheme.copyright};
`;
