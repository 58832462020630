import React from 'react';
import { GenericIconProps } from '../types';
import { useGetIconThemeSize } from '../useGetIconThemeSize.hook';
import * as S from '../styles';

export function Mail({ strokeColor, opacity = 1, size = 'l', className }: GenericIconProps) {
  const themeSize = useGetIconThemeSize(size);
  return (
    <svg
      width={themeSize}
      height={themeSize}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <S.Path
        d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z"
        stroke="currentColor"
        strokeColor={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <S.Path
        d="M3 6L12.2571 13L21 6"
        stroke="currentColor"
        strokeColor={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
