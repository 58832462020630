import { REFRESH_REDUX } from 'src/store/reducers';

import {
  SET_ERROR_NEW,
  SET_VALIDATION_ERROR,
  SET_OFFRE_VALUES,
  SET_OLD_REFERENCES,
  SET_VOLTALIS_PERMISSION,
  SET_EXTERNAL_LEAD_ID,
  SET_LOCATION_VALUES,
  SET_STEPPERS_ROUTES,
} from './constants';


export function setInitLocation(values) {
  return {
    type: SET_LOCATION_VALUES,
    payload: values,
  };
}

export function setOffreValues(values) {
  return {
    type: SET_OFFRE_VALUES,
    payload: values,
  };
}

export function setValidationErrorAction(hasError) {
  return {
    type: SET_VALIDATION_ERROR,
    payload: hasError,
  };
}

export function setErrorNewAction(error) {
  return {
    type: SET_ERROR_NEW,
    payload: error,
  };
}

export function resetErrorNewAction() {
  return {
    type: SET_ERROR_NEW,
    payload: null,
  };
}

export function setOldReferencesAction(oldReferences) {
  return {
    type: SET_OLD_REFERENCES,
    payload: oldReferences,
  };
}

export function setVoltalisConfirmationAction(payload) {
  return {
    type: SET_VOLTALIS_PERMISSION,
    payload,
  };
}

export function refreshReduxAction() {
  return {
    type: REFRESH_REDUX,
  };
}

export function setExternalLeadIdAction(payload: string) {
  return {
    type: SET_EXTERNAL_LEAD_ID,
    payload,
  };
}

export function setSteppersRoutes(payload) {
  return {
    type: SET_STEPPERS_ROUTES,
    payload,
  };
}
