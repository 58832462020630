// This file should contain all helper functions non-related to business logic
import pick from 'lodash/pick';
import { createQuotationSuccessAction } from 'src/hocs/WithDevis/actions';
import API_ROUTES from 'src/utils/api/api-routes';
import { getHeadersFromLocalStorage } from 'src/utils/api/api.helper';
import http from 'src/utils/api/http';
import { Dispatch } from '@reduxjs/toolkit';
import { setOffreValues } from 'src/hocs/App/actions';
import { QuotationRequestType, QuotationResponseType, ServiceOptionType } from './types';
import { QUOTATION_FIELDS } from './constants';

// get a list of IDs from an array
export const getIdsArray = (list: Array<ServiceOptionType>): Array<{ id: string }> =>
  list.map(({ id }) => ({ id }));

// this function assures that no unexpected fields will be sent to the API
export const constraintQuotationPayload = (quotation: QuotationRequestType): QuotationRequestType =>
  pick(quotation, Object.values(QUOTATION_FIELDS));

interface PostQuotation {
  quotation: QuotationResponseType;
  dispatch: Dispatch;
  xSellerId?: string;
  keepServicesOptions?: boolean;
}

export const postQuotation = async ({
  quotation,
  dispatch,
  xSellerId,
  keepServicesOptions,
}: PostQuotation) => {
  const { quotations } = API_ROUTES;
  if (xSellerId) {
    dispatch(
      setOffreValues({
        codeApporteur: xSellerId,
      }),
    );
  }
  const options = keepServicesOptions ? getIdsArray(quotation?.options) : [];
  const services = keepServicesOptions ? getIdsArray(quotation?.services) : [];
  const payload = constraintQuotationPayload({
    ...quotation,
    options,
    services,
  });

  const extraHeaders = getHeadersFromLocalStorage(xSellerId);
  const response = await http.post(quotations, payload, {
    headers: { ...extraHeaders },
  });

  if (response.data) {
    dispatch(createQuotationSuccessAction({ ...response.data }));
  }

  return response.data;
};
