import styled from '@emotion/styled/macro';
import { inputTextTheme } from 'src/theme/tokens/components/form/inputText';
import { WithRequired } from 'src/typings/utils';
import * as T from './types';

const { colors, variants } = inputTextTheme;

interface GetColorArgs {
  hasError?: boolean;
  disabled?: boolean;
}

export const getInputBorderColor = ({ hasError, disabled }: GetColorArgs) => {
  if (disabled) {
    return colors.border.disabled;
  }
  if (hasError) {
    return colors.border.error;
  }
  return colors.border.default;
};

export const Container = styled.div<WithRequired<T.ContainerStyleType, 'iconPosition'>>`
  ${({ isHidden }) => isHidden && 'display: none'};
  position: relative;
  width: 100%;

  & input {
    width: 100%;
    border: ${({ disabled, hasError }) =>
      `${inputTextTheme.borderWidth} solid ${getInputBorderColor({
        disabled,
        hasError,
      })}`};
    border-radius: ${inputTextTheme.radius};
    height: ${({ isInputWithButton }) =>
      isInputWithButton ? inputTextTheme.heightWithButton : inputTextTheme.height};
    font-size: ${variants.mobile.textFontSize};
    line-height: ${variants.mobile.lineHeight};
    color: ${({ disabled }) => (disabled ? colors.text.disabled : colors.text.default)};
    padding: ${({ iconPosition, icon }) =>
      icon ? inputTextTheme.padding[`${iconPosition}Icon`] : inputTextTheme.padding.noIcon};
    background-color: ${({ disabled }) =>
      disabled ? colors.background.disabled : colors.background.default};

    ${({ theme }) => theme.breakpoints.upMedium} {
      font-size: ${variants.desktop.textFontSize};
      line-height: ${variants.desktop.lineHeight};
    }

    &:focus,
    &:hover {
      border-color: ${({ disabled }) =>
        disabled ? colors.border.disabled : colors.border.interaction};
    }

    &::placeholder {
      color: ${colors.text.disabled};
      font-style: normal;
    }
  }
`;

export const InputWrapper = styled.div<{ width?: number; disabled?: boolean }>`
  width: ${({ width = '100%' }) => `${width}px`};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

export const IconContainer = styled.div<{
  iconPosition?: 'left' | 'right' | 'both';
  isLeftIcon?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  ${({ iconPosition, isLeftIcon }) => `

    right: ${
      (iconPosition === 'right' || (iconPosition === 'both' && !isLeftIcon)) &&
      inputTextTheme.iconPosition
    };
  `};
  top: 50%;
  transform: translateY(-50%);
  height: ${({ theme }) => theme.sizes.icon.m}px;
  width: ${({ theme }) => theme.sizes.icon.m}px;
  ${({ disabled }) => disabled && 'filter: grayscale(1);'};
`;
