import React from 'react';
import MaskedInput from 'src/components/atoms/form/deprecated/MaskedInput';
import InputLabel from 'src/components/atoms/form/InputLabel';
import ErrorMessage from 'src/components/atoms/form/ErrorMessage';
import * as S from './styles';
import * as T from './types';

interface GetIconArgs {
  position: 'left' | 'right';
  isLeft?: boolean;
}

function Input({
  label = null,
  name = null,
  placeHolder = null,
  errorMessage,
  onChange,
  onFocus,
  onKeyDown,
  onPaste,
  onBlur,
  onClick,
  id,
  maxLength = null,
  icon,
  error,
  defaultValue = null,
  formRef,
  type = 'text',
  disabled,
  isHidden,
  mask,
  control,
  pattern,
  inputMode,
  caption,
  iconPosition = 'right',
  isInputWithButton = false,
  min,
  max,
  width,
}: T.InputTextType) {
  const inputProps = {
    id,
    name,
    type,
    placeholder: placeHolder,
    onFocus,
    onBlur,
    onKeyDown,
    onChange,
    defaultValue: defaultValue && defaultValue,
    ref: formRef,
    onPaste,
    disabled,
    maxLength,
    mask,
    control,
    pattern,
    inputMode,
    min,
    max,
  };

  const getIcon = ({ position, isLeft }: GetIconArgs) =>
    [position, 'both'].includes(iconPosition) &&
    icon && (
      <S.IconContainer disabled={disabled} iconPosition={iconPosition} isLeftIcon={isLeft}>
        {icon}
      </S.IconContainer>
    );

  const errorMessageType = () => {
    if (error) {
      return error.message || errorMessage;
    }
    if (caption) {
      return caption;
    }
    return null;
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    e.target.value = e.target.value.trim();
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <S.Container
      onClick={onClick}
      icon={icon}
      iconPosition={iconPosition}
      disabled={disabled}
      hasError={!!error}
      isHidden={isHidden}
      isInputWithButton={isInputWithButton}
    >
      {label && (
        <InputLabel disabled={disabled} htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <S.InputWrapper disabled={disabled} width={width}>
        {getIcon({
          position: 'left',
          isLeft: true,
        })}
        {mask ? (
          <MaskedInput
            name={name}
            // @ts-ignore TODO error from js migration
            formRef={formRef}
            control={control}
            mask={mask}
            defaultValue={defaultValue}
            onChange={onChange}
            id={id}
          />
        ) : (
          <input
            {...inputProps}
            onChange={onChange}
            onWheel={(e) => {
              e.currentTarget.blur();
              e.stopPropagation();
            }}
            onBlur={handleBlur}
          />
        )}
        {getIcon({
          position: 'right',
          isLeft: false,
        })}
      </S.InputWrapper>
      {(error || caption) && (
        <ErrorMessage disabled={disabled} isInputWithButton={isInputWithButton}>
          {errorMessageType()}
        </ErrorMessage>
      )}
    </S.Container>
  );
}

export default Input;
