import React from 'react';
import * as S from './styles';
import * as T from './types';

const InputLabel = ({ children, disabled, error }: T.InputLabelType) => (
  <S.Label disabled={disabled} error={error}>
    {children}
  </S.Label>
);

export default InputLabel;
