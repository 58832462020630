import * as T from './types';
import * as S from './styles';

const LinkContent = ({ isReverted, chevron, linkText }: T.LinkContentProps) => (
  <S.LinkContent isReverted={isReverted}>
    {chevron !== 'none' && (
      <S.ChevronContainer chevron={chevron} isReverted={isReverted}>
        <S.ChevronLink size="m" chevron={chevron} />
      </S.ChevronContainer>
    )}
    <span>{linkText}</span>
  </S.LinkContent>
);

const Link = ({
  dataTestId,
  linkText,
  to,
  chevron = 'right',
  href,
  variants = 'primary',
  isReverted = false,
  hasComma = false,
  isUnderlined = true,
  ...rest
}: T.LinkProps) =>
  to ? (
    <S.RouterLink
      chevron={chevron}
      isReverted={isReverted}
      to={to}
      data-testid={dataTestId}
      variants={variants}
      isUnderlined={isUnderlined}
      {...rest}
    >
      <LinkContent isReverted={isReverted} chevron={chevron} linkText={linkText} />
    </S.RouterLink>
  ) : (
    <>
      <S.ClassicLink
        chevron={chevron}
        href={href}
        data-testid={dataTestId}
        variants={variants}
        isReverted={isReverted}
        isUnderlined={isUnderlined}
        {...rest}
      >
        <LinkContent isReverted={isReverted} chevron={chevron} linkText={linkText} />
      </S.ClassicLink>
      {hasComma && ','}
    </>
  );

export default Link;
