import { resetErrorNewAction } from 'src/hocs/App/actions';
import { postQuotation } from 'src/hocs/WithDevis/logic.helper';
import { BUSINESS_PARTS } from 'src/hocs/App/constants';
import routes from 'src/utils/routes/routes';
import type { QuotationResponseType } from 'src/hocs/WithDevis/types';
import type { Dispatch } from '@reduxjs/toolkit';
import type { CallHistoryMethodAction } from 'connected-react-router';

interface HandleCloseModalArgs {
  dispatch: Dispatch;
  quotation: QuotationResponseType;
}

interface HandleChangeOfferArgs extends HandleCloseModalArgs {
  navigateTo: (
    route: string,
    urlParams?: Record<string, unknown>,
    state?: Record<string, unknown>,
  ) => CallHistoryMethodAction<[string, Record<string, unknown>?]>;
}

export const handleCloseModal = async ({ dispatch, quotation }: HandleCloseModalArgs) => {
  try {
    await postQuotation({ quotation, dispatch, keepServicesOptions: true });
  } catch (error) {
    console.error('Something went wrong in handleCloseModal()', error);
  } finally {
    dispatch(resetErrorNewAction());
  }
};

export const handleChangeOffer = async ({
  dispatch,
  quotation,
  navigateTo,
}: HandleChangeOfferArgs) => {
  try {
    await postQuotation({
      quotation,
      dispatch,
      xSellerId: BUSINESS_PARTS.EKWATEUR,
    });
  } catch (error) {
    console.error('Something went wrong in handleChangeOffer()', error);
  } finally {
    dispatch(resetErrorNewAction());
    navigateTo(routes.devis);
  }
};
