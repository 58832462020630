import React from 'react';
import * as S from './styles';
import * as T from './types';

const ErrorMessage = ({ children, isInputWithButton, disabled }: T.ErrorMessageType) => (
  <S.Message disabled={disabled} isInputWithButton={isInputWithButton}>
    {children}
  </S.Message>
);

export default ErrorMessage;
