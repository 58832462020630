import React from 'react';
import { GenericIconProps } from '../types';
import { useGetIconThemeSize } from '../useGetIconThemeSize.hook';
import * as S from '../styles';

export function Close({ strokeColor, opacity = 1, size = 'l', className }: GenericIconProps) {
  const themeSize = useGetIconThemeSize(size);
  return (
    <svg
      width={themeSize}
      height={themeSize}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <S.Path
        d="M21 21L3 3"
        stroke="currentColor"
        strokeColor={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <S.Path
        d="M21 3L2.99994 21.0001"
        stroke="currentColor"
        strokeColor={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
