import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SendEmailForm from 'src/components/organisms/SendEmailForm';
import Text from 'src/components/atoms/typography/Text';
import { saveELDSubscribeAction } from 'src/components/pages/SouscrireNew/SouscrireNew.actions';
import { SendEmailFormType } from 'src/components/organisms/SendEmailForm/types';
import { SaveEmailRequestType } from 'src/hocs/WithSubscriptionNew/types';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

export const DisallowELDDuringWinterBreak = () => {
  const dispatch = useDispatch();
  const { intlMessage } = useIntlMessage();
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const submitForm = (data: SaveEmailRequestType) => dispatch(saveELDSubscribeAction(data));
  const handleSubmitForm = (formData: SendEmailFormType) => {
    submitForm({ email: formData.nonEligibleEmail });
    setIsEmailSubmitted(true);
  };

  return (
    <>
      <Text>{intlMessage('app.errorManager.DisallowELDDuringWinterBreakText')}</Text>
      <br />
      {!isEmailSubmitted ? (
        <SendEmailForm
          initialValue=""
          onSubmit={handleSubmitForm}
          active={!isEmailSubmitted}
          buttonLabel={intlMessage('app.errorManager.DisallowELDDuringWinterBreakButton')}
        />
      ) : (
        <Text>
          {intlMessage('app.errorManager.DisallowELDDuringWinterBreakConfirmationMessage')}
        </Text>
      )}
    </>
  );
};
