import classNames from 'classnames';
import { RowNewProps } from './types';
import * as S from './styles';

const Row = (props: RowNewProps) => {
  const {
    children,
    align,
    padding,
    style,
    bottom = 40,
    className,
    wrap,
    noWrap,
    tp = 12,
    xs = 12,
    md = 12,
    lg = 12,
    noInlineStyle = false,
    onClick,
    ...rest
  } = props;
  return (
    <S.Row
      className={classNames({
        Row: true,
        [`Row--${align}`]: align,
        'Row--wrap': wrap,
        'Row--noWrap': noWrap,
        [className]: className,
      })}
      style={
        !noInlineStyle
          ? {
              ...style,
              padding,
              marginBottom: bottom,
            }
          : undefined
      }
      {...{
        tp,
        xs,
        md,
        lg,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </S.Row>
  );
};

export default Row;
