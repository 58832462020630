import { useSelector, useDispatch } from 'react-redux';
import Link from 'src/components/atoms/Link';
import Text from 'src/components/atoms/typography/Text';
import Button from 'src/components/atoms/Button';
import Column from 'src/components/atoms/layouts/ColumnNew';
import Row from 'src/components/atoms/layouts/RowNew';
import { resetErrorNewAction } from 'src/hocs/App/actions';
import { makeSelectCustomerService } from 'src/hocs/WithCustomerService/selectors';
import { makeSelectSubscription } from 'src/hocs/WithSubscription/selectors';
import { SubscriptionStatusEnum } from 'src/hocs/WithSubscriptionNew/types';
import { useNavigateTo } from 'src/hooks/useNavigateTo';
import { purgeReduxAndPersistor, isHomePage } from 'src/store/emptyReduxCache';
import { isBusinessHour } from 'src/utils/date/dateConverter';
import routes from 'src/utils/routes/routes';
import { isProgressionSavable } from 'src/utils/routes/routes.helper';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import { GenericPauseSubscriptionForm } from './GenericPauseSubscriptionForm/GenericPauseSubscriptionForm';

export interface GenericErrorPropsType {
  isTryAgainLinkAvailable?: boolean;
}

export const GenericError = ({ isTryAgainLinkAvailable = true }: GenericErrorPropsType) => {
  const subscription = useSelector(makeSelectSubscription());
  const { emailAddress, phoneNumber, contactChannel } = useSelector(makeSelectCustomerService());
  const { navigateTo } = useNavigateTo();
  const dispatch = useDispatch();
  const { intlMessage } = useIntlMessage();

  const onClick = async () => {
    dispatch(resetErrorNewAction());
    await purgeReduxAndPersistor();
    const initialRouteIsHomePage = isHomePage();
    const hasSubscriptionError = subscription?.status === SubscriptionStatusEnum.FINALISATION_ERROR;
    navigateTo(routes.souscrire, {});
    // edge case: can't delete input fields data without reloading the entire page
    if (initialRouteIsHomePage || hasSubscriptionError) window.location.reload();
  };
  const isOverStep2 = isProgressionSavable();

  return (
    <Column bottom={0}>
      <Row bottom={16}>
        <Text type="l">
          {isBusinessHour()
            ? intlMessage('app.genericErrorMessage', {
                contactChannel,
                phoneNumber,
                emailAddress,
              })
            : intlMessage('app.genericErrorMessageLate', {
                emailAddress,
              })}
        </Text>
      </Row>
      {isTryAgainLinkAvailable && (
        <Row bottom={isOverStep2 ? 16 : 0} align="left">
          {isOverStep2 ? (
            <Link
              id="tryAgainLinkButton"
              data-testId="tryAgainLinkButton"
              linkText={intlMessage('app.genericErrorButton')}
              onClick={onClick}
              to=""
            />
          ) : (
            <Button onClick={onClick} label={intlMessage('app.genericErrorButton')} />
          )}
        </Row>
      )}
      {isOverStep2 && <GenericPauseSubscriptionForm />}
    </Column>
  );
};
