import React from 'react';
import { ColumnNewProps } from './types';
import * as S from './styles';

const Column = (props: ColumnNewProps) => {
  const {
    id,
    className,
    bottom,
    padding,
    whiteSpace,
    children,
    align,
    tp = 12,
    xs = 12,
    md = 12,
    lg = 12,
    'data-testid': dataTestId,
    ...rest
  } = props;

  const alignItemsValue: {
    left: string;
    center: string;
    right: string;
  } =
    {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    }[align] || '';

  return (
    <S.Column
      id={id}
      className={className}
      data-testid={dataTestId}
      alignItemsValue={alignItemsValue}
      alignContentValue={align === 'bottom'}
      justifyContentValue={align === 'top'}
      paddingBottom={bottom}
      padding={padding}
      whiteSpace={whiteSpace}
      tp={tp}
      xs={xs}
      md={md}
      lg={lg}
      {...rest}
    >
      {children}
    </S.Column>
  );
};

export default Column;
