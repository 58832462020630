import Button from 'src/components/atoms/Button';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import { useNavigateTo } from 'src/hooks/useNavigateTo';
import { purgeReduxAndPersistor } from 'src/store/emptyReduxCache';
import routes from 'src/utils/routes/routes';
import Text from 'src/components/atoms/typography/Text';

export const NoCompatibleOffer = () => {
  const { navigateTo } = useNavigateTo();
  const { intlMessage } = useIntlMessage();
  const tenantName = intlMessage('shared.i18n.tenantName');

  const handleClickButton = async () => {
    await purgeReduxAndPersistor();
    navigateTo(routes.souscrire, {});
    // edge case: can't delete input fields data without reloading the entire page
    window.location.reload();
  };

  return (
    <>
      <Text type="l">{intlMessage('app.errorManager.noCompatibleOfferText', { tenantName })}</Text>
      <br />
      <Button
        onClick={handleClickButton}
        label={intlMessage('app.errorManager.noCompatibleOfferText', {
          tenantName,
        })}
      />
    </>
  );
};
