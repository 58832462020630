import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/atoms/Button';
import Input from 'src/components/atoms/form/InputNew';
import { InputWithButton } from 'src/components/molecules/InputWithButton';
import { FormDataType } from 'src/utils/form/form.helper.types';
import { getInputPropsFromName } from 'src/utils/form/form.helper';
import { SendEmailFormSchema } from './validationSchema';

import messages from './messages';
import * as t from './types';
import * as c from './constants';

interface SendEmailFormProps {
  initialValue: string | null | undefined;
  onSubmit: (data: t.SendEmailFormType) => void;
  active: boolean;
  buttonLabel: React.ReactNode;
}

const SendEmailForm = ({ initialValue, onSubmit, active, buttonLabel }: SendEmailFormProps) => {
  const { formatMessage } = useIntl();
  const sendEmailForm = useForm<t.SendEmailFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(SendEmailFormSchema(formatMessage)),
  });

  const defaultValues = {
    [c.FORM_FIELDS.NON_ELIGIBLE_EMAIL]: initialValue,
  };

  const formData: FormDataType<t.SendEmailFormType> = {
    ...sendEmailForm,
    defaultValues,
  };

  return (
    <form onSubmit={formData.handleSubmit(onSubmit)}>
      <InputWithButton>
        <Input
          placeHolder={formatMessage(messages.emailPlaceholder)}
          {...getInputPropsFromName(c.FORM_FIELDS.NON_ELIGIBLE_EMAIL, formData)}
          id="nonEligibleEmail"
          isInputWithButton
        />
        <Button id="nonEligibleBtn" disabled={!active} label={buttonLabel} isInputWithButton />
      </InputWithButton>
    </form>
  );
};

export default SendEmailForm;
