import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'src/utils/saga/injectSaga';
import Text from 'src/components/atoms/typography/Text';
import Modal from 'src/components/organisms/Modal';
import { images } from 'src/theme/assets/images';
import { DAEMON } from 'src/utils/saga/constants';
import { makeSelectCustomerService } from 'src/hocs/WithCustomerService/selectors';
import { resetErrorAction } from './actions';
import { ERROR_SAGA } from './constants';
import { makeSelectError } from './selectors';
import { getErrorMessage } from './logic.helper';
import saga from './saga';

export const Error = () => {
  useInjectSaga({
    key: ERROR_SAGA,
    saga,
    mode: DAEMON,
  });
  const { formatMessage } = useIntl();
  const { emailAddress, phoneNumber, contactChannel } = useSelector(makeSelectCustomerService());
  const dispatch = useDispatch();
  const error = useSelector(makeSelectError());
  const resetError = () => dispatch(resetErrorAction());
  if (!error) return null;

  const { errorMessageToDisplay, headingText, closable } = getErrorMessage({ error });

  return (
    <Modal
      id="AppErrorModal"
      open={!!error}
      illustration={images.genericError}
      headingText={headingText}
      alignContent="center"
      closable={closable}
      onClose={resetError}
    >
      {errorMessageToDisplay.id && (
        <Text type="l">
          {formatMessage(errorMessageToDisplay, {
            contactChannel,
            phoneNumber,
            emailAddress,
          })}
        </Text>
      )}
    </Modal>
  );
};
