import { Suspense, lazy } from 'react';
import { DidomiSDK } from '@didomi/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FeatureFlagComponent } from 'src/components/utils/FeatureFlagComponent';
import { FEATURES_NAMES } from 'src/utils/featureFlag/types';
import routes from 'src/utils/routes/routes';
import { LoadingModal } from 'src/components/organisms/LoadingModal/LoadingModal';
import { redirectTo, goToPrefill } from 'src/App.logic-helpers';
import AppConfig from './config/config';

import { AppRouterProps } from './App.types';

const { REACT_APP_COOKIES_API_KEY } = AppConfig;

const SouscrireNew = lazy(() => import('src/components/pages/SouscrireNew/SouscrireNew'));
const Quotation = lazy(() => import('src/components/pages/Quotation/Quotation'));
const Contrat = lazy(() => import('src/components/pages/Contrat'));
const ChoixEnergieNew = lazy(() => import('src/components/pages/ChoixEnergieNew'));
const NonEligible = lazy(() => import('src/components/pages/NonEligible'));
const ServicesOptions = lazy(() => import('src/components/pages/ServicesOptions'));
const ModeFacturation = lazy(() => import('src/components/pages/ModeFacturation'));
const MyDelivery = lazy(() => import('src/components/pages/MyDelivery/MyDelivery'));
const Estimation = lazy(() => import('src/components/pages/Estimation'));
const LivraisonPod = lazy(() => import('src/components/pages/LivraisonPod'));
const Summary = lazy(() => import('src/components/pages/Summary'));
const GroupPurchaseLanding = lazy(() => import('src/components/pages/GroupPurchase/Landing'));
const GroupPurchaseSummary = lazy(() => import('src/components/pages/GroupPurchase/Summary'));
const PaiementSuccess = lazy(() => import('src/components/pages/PaiementSuccess'));
const Payment = lazy(() => import('src/components/pages/Payment'));
const UnPaid = lazy(() => import('src/components/pages/UnPaid'));
const AlreadyClient = lazy(() => import('src/components/pages/AlreadyClient'));
const Reprise = lazy(() => import('src/components/pages/Reprise'));
const ResumeWithoutPOD = lazy(() => import('src/components/pages/ResumeWithoutPOD'));
const Maintenance = lazy(() => import('src/components/pages/Maintenance'));
const MandateSignature = lazy(() => import('src/components/pages/MandateSignature'));
const MandateSignatureFinalized = lazy(
  () => import('src/components/pages/MandateSignatureFinalized'),
);

export const AppRouter = ({
  hasCatch,
  location,
  eligibility,
  subscription,
  devis,
  loading,
  setValidationError,
  validationError,
}: AppRouterProps) =>
  !hasCatch && (
    <Suspense fallback={<LoadingModal />}>
      <Switch location={location}>
        <Route exact path={routes.souscrire} component={SouscrireNew} key="subscribe" />
        <Route exact path={routes.nonEligible} component={NonEligible} key="non-eligible" />
        <Route
          exact
          path={routes.estimation}
          render={() =>
            redirectTo({
              condition: eligibility,
              component: <Estimation />,
            })
          }
          key="estimation"
        />
        <Route
          exact
          path={routes.choixEnergie}
          render={() =>
            redirectTo({
              condition: eligibility,
              component: <ChoixEnergieNew />,
            })
          }
          key="energy-choice"
        />
        <Route
          exact
          path={routes.devis}
          render={() =>
            redirectTo({
              condition: devis,
              component: <Quotation />,
            })
          }
          key="quotation"
        />
        <Route
          exact
          path={routes.serviceOption}
          render={() =>
            redirectTo({
              condition: devis,
              component: <ServicesOptions setValidationError={setValidationError} />,
            })
          }
          key="services"
        />
        <Route
          exact
          path={routes.contrat}
          render={() =>
            redirectTo({
              condition: devis,
              component: <Contrat />,
            })
          }
          key="contract"
        />
        <Route
          exact
          path={routes.modeFacturation}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <ModeFacturation />,
            })
          }
          key="billing-mode"
        />
        <Route
          exact
          path={routes.summary}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <Summary />,
            })
          }
          key="summary"
        />
        <Route
          exact
          path={routes.groupPurchaseSummary}
          component={GroupPurchaseSummary}
          key="group-purchase-summary"
        />
        <Route
          exact
          path={routes.myDelivery}
          render={(rest) =>
            redirectTo({
              condition: subscription,
              component: (
                <MyDelivery
                  // @ts-ignore TODO js migration
                  loading={loading}
                  setValidationError={setValidationError}
                  validationError={validationError}
                  {...rest}
                />
              ),
            })
          }
          key="my-delivery"
        />
        <Route
          exact
          path={routes.livraisonPod}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <LivraisonPod />,
            })
          }
          key="pod-delivery"
        />
        <Route
          exact
          path={routes.paiement}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <Payment />,
            })
          }
          key="payment"
        />
        <Route
          exact
          path={routes.paiementSuccess}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <PaiementSuccess />,
            })
          }
          key="payment-success"
        />
        <Route
          exact
          path={routes.unPaid}
          render={() =>
            redirectTo({
              condition: devis,
              component: <UnPaid />,
            })
          }
          key="unpaid"
        />
        <Route
          exact
          path={routes.alreadyClient}
          render={() =>
            redirectTo({
              condition: devis,
              component: <AlreadyClient />,
            })
          }
          key="already-client"
        />
        <Route
          exact
          path={routes.resumeWithoutPOD}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <ResumeWithoutPOD />,
            })
          }
          key="resume-without-pod"
        />
        <Route exact path={[routes.reprise, routes.repriseNoId]} component={Reprise} key="resume" />
        <Route exact path={routes.prefill} render={(rest) => goToPrefill(rest)} key="prefill" />
        <Route
          exact
          path={`${routes.mandateSignature}/:subscriptionId`}
          component={MandateSignature}
          key="mandate-signature"
        />
        <Route
          exact
          path={routes.mandateSignatureFinalized}
          render={() =>
            redirectTo({
              condition: subscription,
              component: <MandateSignatureFinalized />,
            })
          }
          key="finalised-mandate-signature"
        />
        <Route exact path={routes.maintenance} component={Maintenance} key="maintenance" />
        <Route
          exact
          path={routes.groupPurchaseLanding}
          component={GroupPurchaseLanding}
          key="group-purchase-landing"
        />
        {/* If url is unknown, it will redirect to home page */}
        <Route>
          <Redirect push to="/" />
        </Route>
      </Switch>
      <FeatureFlagComponent featureName={FEATURES_NAMES.FF_SCRIPT_DIDOMI}>
        <DidomiSDK
          apiKey={REACT_APP_COOKIES_API_KEY}
          iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
          gdprAppliesGlobally
          sdkPath="https://sdk.privacy-center.org/"
          embedTCFStub
        />
      </FeatureFlagComponent>
    </Suspense>
  );
