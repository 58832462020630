import { v4 as uuid } from 'uuid';
import { STEPPER_INDEXES } from 'src/components/ui/stepper/Stepper.constants';
import routes from 'src/utils/routes/routes';
import AppConfig from 'src/config/config';
import {
  LOADING,
  RESET_STATE,
  SET_FULL_OFFER,
  SET_OFFRE_VALUES,
  SET_VALIDATION_ERROR,
  SET_COUNTER,
  SET_DISPLAY_INSEE,
  SET_ERROR_NEW,
  SET_OLD_REFERENCES,
  SET_VOLTALIS_PERMISSION,
  SET_EXTERNAL_LEAD_ID,
  SET_STEPPERS_ROUTES,
} from './constants';
import { AppReducerType } from './types';

const { REACT_APP_TENANT } = AppConfig;
const { FIRST_STEPPER, SECOND_STEPPER } = STEPPER_INDEXES;

export const initialState: AppReducerType = {
  loading: false,
  validationError: false,
  token: null,
  uuid: uuid(),
  counter: 78000,
  fullOffer: null,
  displayInsee: false,
  offre: {
    codeOffre: null,
    codeCanal: 'PSFO',
    codeApporteur: REACT_APP_TENANT?.toUpperCase(),
  },
  errorNew: null,
  oldReferences: {
    oldCustomerId: null,
    oldContractId: null,
  },
  usrVoltalisPermission: true,
  voltalisChoiceDone: false,
  steppersRoutes: {
    steppers: {
      [FIRST_STEPPER]: [routes.souscrire],
      [SECOND_STEPPER]: [],
    },
    isFirstStepperRoute: true,
  },
  externalLeadId: null,
};

function AppReducer(state = initialState, action): AppReducerType {
  switch (action.type) {
    case SET_VALIDATION_ERROR: {
      return {
        ...state,
        validationError: action.payload,
      };
    }
    case SET_OFFRE_VALUES: {
      return {
        ...state,
        offre: {
          ...state.offre,
          ...action.payload,
        },
      };
    }
    case SET_FULL_OFFER:
      return {
        ...state,
        fullOffer: action.payload,
      };
    case SET_COUNTER:
      return {
        ...state,
        counter: action.payload,
      };
    case SET_DISPLAY_INSEE:
      return {
        ...state,
        displayInsee: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ERROR_NEW:
      return {
        ...state,
        errorNew: action.payload,
      };
    case RESET_STATE: {
      return {
        ...state,
        loading: false,
        validationError: false,
        token: null,
        uuid: uuid(),
        fullOffer: null,
      };
    }
    case SET_OLD_REFERENCES: {
      return {
        ...state,
        oldReferences: action.payload,
      };
    }
    case SET_VOLTALIS_PERMISSION: {
      return {
        ...state,
        usrVoltalisPermission: action.payload,
        voltalisChoiceDone: true,
      };
    }
    case SET_STEPPERS_ROUTES: {
      return {
        ...state,
        steppersRoutes: action.payload,
      };
    }
    case SET_EXTERNAL_LEAD_ID: {
      return {
        ...state,
        externalLeadId: action.payload,
      };
    }
    default:
      return state;
  }
}

export default AppReducer;
