import { css } from '@emotion/react/macro';

import { theme } from 'src/theme';
import { textTheme } from 'src/theme/tokens/components/text';
import { TextStyleArgs } from 'src/components/atoms/typography/Text/types';
import { getTypographyVariantsValues } from '../utils/logic.helper';

export const textStyle = ({
  type,
  weight,
  align,
  noWrap,
  color,
  isItalic,
  margin,
  isUnderline,
}: TextStyleArgs) => css`
  margin: 0;
  ${color && `color: ${textTheme.colors[color]}`};
  ${weight && `font-weight: ${theme.fonts.weights[weight]}`};
  ${align && `text-align: ${align}`};
  ${noWrap && `white-space: ${noWrap}`};
  ${isItalic && 'font-style: italic'};
  ${getTypographyVariantsValues({ variant: textTheme, type, device: 'mobile' })};

  ${theme.breakpoints.upMedium} {
    ${getTypographyVariantsValues({ variant: textTheme, type, device: 'desktop' })};
  }
  ${margin && `margin: ${margin}`};
  ${isUnderline && 'text-decoration: underline'};
`;
