/* eslint-disable import/no-duplicates */
import { addDays, format, isWeekend } from 'date-fns';
import routes from 'src/utils/routes/routes';
import fr from 'date-fns/locale/fr';

export const isGroupPurchasePath = (): boolean => {
  const currentLocation = window.location.pathname;
  const groupPurchaseRoutes = [routes.groupPurchaseLanding, routes.groupPurchaseSummary];
  return groupPurchaseRoutes.some((route) => currentLocation.includes(route));
};

const addBusinessDays = (startDate: Date, numberOfDays: number) => {
  let endDate = startDate;
  let addedDays = 0;

  while (addedDays < numberOfDays) {
    endDate = addDays(endDate, 1);
    if (!isWeekend(endDate)) {
      addedDays += 1;
    }
  }

  return endDate;
};

const currentDate = new Date();
const desiredActivationDate = addDays(currentDate, 4);
export const formattedDesiredActivationDate: string = format(desiredActivationDate, 'yyyy-MM-dd');

const sendingContractBusinessDate: Date = addBusinessDays(currentDate, 4);
export const formattedSendingContractDateModal = format(
  sendingContractBusinessDate,
  'eeee dd MMMM y',
  {
    locale: fr,
  },
);
