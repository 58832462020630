import { ValueOf } from 'src/utils/typescript/typescript.helper';
import { ErrorDefinitionType } from 'src/utils/errorManager/types';
import { FirstStepperType, SecondStepperType } from 'src/components/ui/stepper/Stepper.types';
import { STEPPER_INDEXES } from 'src/components/ui/stepper/Stepper.constants';

const { FIRST_STEPPER, SECOND_STEPPER } = STEPPER_INDEXES;

export interface OffreType {
  codeOffre: string;
  codeCanal: string;
  codeApporteur: string;
}

export interface AppReducerType {
  loading: boolean;
  validationError: boolean;
  token: string | null;
  uuid: string;
  counter: number;
  /** TODO refine unknown type */
  fullOffer: unknown | null;
  displayInsee: boolean;
  offre: {
    codeOffre: string | null;
    codeCanal: string; // 'PSFO'
    codeApporteur: string;
  };
  errorNew: ErrorDefinitionType | null;
  /** TODO refine unknown type */
  oldReferences?: {
    oldCustomerId: string | null;
    oldContractId: string | null;
  };
  usrVoltalisPermission: boolean;
  voltalisChoiceDone: boolean;
  externalLeadId: string | null;
  steppersRoutes: {
    steppers: {
      [FIRST_STEPPER]: FirstStepperType[];
      [SECOND_STEPPER]: SecondStepperType[];
    };
    isFirstStepperRoute: boolean;
  };
}

export const BaseEnergyEnum = {
  ELECTRICITY: 'ELECTRICITY',
  GAS: 'GAS',
};

export const EnergyEnum = {
  ...BaseEnergyEnum,
  DUAL: 'DUAL',
};

export type EnergyType = ValueOf<typeof EnergyEnum>;

export const ElectricityGasEnum = { ...BaseEnergyEnum };

export type ElectricityGasEnumType = ValueOf<typeof ElectricityGasEnum>;

export const ElectricityDistributorsEnum = {
  ENEDIS: 'ENEDIS',
  DISCO: 'DISCO',
  GINKO: 'GINKO',
  GEG: 'GEG',
  URM: 'URM',
  ESR: 'ESR',
} as const;
export type ElectricityDistributorsType = ValueOf<typeof ElectricityDistributorsEnum>;

export const GasDistributorsEnum = {
  GRDF: 'GRDF',
  RGDS: 'RGDS',
  GEG: 'GEG',
} as const;
export type GasDistributorsType = ValueOf<typeof GasDistributorsEnum>;

export const PodTypes = {
  PDL: 'PDL',
  PCE: 'PCE',
} as const;
export type PodTypesType = ValueOf<typeof PodTypes>;

export const MeterTypeCodeEnum = {
  ELECTRICITY_SMART_METER: 'LINKY',
  CBE: 'CBE',
  EMC: 'EMC',
  GAS_SMART_METER: 'Gazpar',
  MEC: 'MEC',
} as const;
export type MeterTypeCodeType = ValueOf<typeof MeterTypeCodeEnum>;
