import { TextType } from './types';
import { Tag } from '../utils/types';

/**
 * FIXME: if we look at getTypographyVariantsValues(), we have mixed sizes and default html elements.
 * add unset type to handle block and inline elements.
 */
export const TYPE_TO_TAG: { [key in TextType]: Tag } = {
  l: 'p',
  m: 'p',
  s: 'span',
  xs: 'span',
  '2xs': 'span',
  '3xs': 'span',
  unset: 'span',
} as const;
