import styled from '@emotion/styled/macro';
import { appTheme } from 'src/theme/tokens/components/app';
import Text from 'src/components/atoms/typography/Text';
import { invalidAttributeInjectionGuardian } from 'src/utils/styles/styles.helper';
import { isGroupPurchasePath } from 'src/components/pages/GroupPurchase/Landing/logic-helper';
import { semantical } from 'src/theme/tokens/semantical';

const headerTheme = {
  background: semantical.colors.background.neutral.strongest,
  dividerBorder: semantical.colors.border.neutral.strong,
} as const;

const isGroupPurchase = isGroupPurchasePath();

export const AppHeader = styled.header`
  background-color: ${headerTheme.background};
  box-shadow: ${appTheme.boxShadow};
  height: 64px;
  padding: ${({ theme }) => `0 ${theme.spacing.m}`};
  position: relative;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex['z-20']};

  ${({ theme }) => theme.breakpoints.upTablet} {
    height: 80px;
    padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
    position: fixed;
  }

  ${({ theme }) => theme.breakpoints.upMedium} {
    height: 80px;
    padding: ${({ theme }) => `0 ${theme.spacing['4xl']}`};
    position: fixed;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.sizes.layout.maxLayoutWidth};
  width: 100%;
`;

export const AppLogoContainer = styled.a`
  margin-right: ${({ theme }) => (isGroupPurchase ? theme.spacing.s : theme.spacing.l)};
  height: 40px;
  width: 136px;

  ${({ theme }) => theme.breakpoints.upTablet} {
    margin-left: 0;
    width: 210px;
  }
`;

export const AppLogo = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

export const StepTitle = styled(Text, invalidAttributeInjectionGuardian())<{
  hasPartnerLogo: boolean;
}>`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  padding-left: ${({ theme }) => theme.spacing.l};
  height: 38px;
  line-height: ${({ theme }) => theme.fonts.lineHeights['3xl']} !important;
  border-left: 1px solid ${headerTheme.dividerBorder};

  ${({ theme }) => theme.breakpoints.small} {
    display: none;
  }
`;

export const PhoneButtonContainer = styled.div`
  margin-left: auto;
`;

export const PartnerLogo = styled.img`
  margin-right: ${({ theme }) => theme.spacing.m};
  max-width: 115px;
  max-height: 55px;
  padding: ${({ theme }) => theme.spacing['2xs']};

  ${({ theme }) => theme.breakpoints.upMedium} {
    max-width: 145px;
    max-height: 80px;
  }
`;
export const GroupPurchaseLogo = styled.img`
  margin-right: ${({ theme }) => theme.spacing.xs};
  max-width: 80px;
  max-height: 40px;
  padding: ${({ theme }) => theme.spacing['2xs']};

  ${({ theme }) => theme.breakpoints.upMedium} {
    max-width: 145px;
    max-height: 55px;
  }
`;

export const SelectraGroupPurchaseText = styled.div`
  padding-right: ${({ theme }) => theme.spacing.xs};
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
