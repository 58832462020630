import { jsx } from '@emotion/react/macro';
import { headingStyle } from './styles';
import * as T from './types';
import { TYPE_TO_TAG } from './constants';

const Heading = ({
  children,
  align,
  type = 'h1',
  color,
  noWrap = false,
  noMarginBottom = false,
  weight = 'bold',
  ...otherProps
}: T.HeadingProps) =>
  jsx(TYPE_TO_TAG[type], {
    css: headingStyle({ type, align, noWrap, color, noMarginBottom, weight }),
    children,
    ...otherProps,
  });

export default Heading;
