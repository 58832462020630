import React, { ComponentType } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';

import 'src/theme';
import { checkIfRoutesHasStickyBanner } from 'src/utils/routes/routes';
import { Header } from 'src/components/organisms/Header/Header';
import { Footer } from 'src/components/organisms/Footer';
import { Error } from 'src/components/organisms/Error';
import { ErrorModal } from 'src/components/organisms/ErrorModal/ErrorModal';
import { LoadingModal } from 'src/components/organisms/LoadingModal/LoadingModal';
import { resetErrorAction } from 'src/components/organisms/Error/actions';
import WithDevis from 'src/hocs/WithDevis';
import WithSubscription from 'src/hocs/WithSubscription';
import WithEligibility from 'src/hocs/WithEligibilityNew';

import { footerLinkList } from 'src/hocs/App/business.helper';

import { CAR_CONSENT_MODAL } from 'src/utils/constants';

import { AppRouter } from 'src/AppRouter';
import { FEATURES_NAMES } from 'src/utils/featureFlag/types';
import { isFeatureEnabled } from 'src/utils/featureFlag';
import AppConfig from 'src/config/config';
import {
  makeSelectErrorNew,
  makeSelectLoading,
  makeSelectValidationError,
} from './hocs/App/selectors';
import { resetErrorNewAction, setInitLocation, setValidationErrorAction } from './hocs/App/actions';
import { MODAL_ERROR_ID } from './hocs/App/constants';
import { AppProps } from './App.types';
import 'react-phone-number-input/style.css';

const { REACT_APP_GOOGLE_TAG_MANAGER_KEY, REACT_APP_TENANT } = AppConfig;

// Todo: refactoring - replace Class Component by Functional Component (with hooks)
export class App extends React.PureComponent<AppProps> {
  state = {
    error: null,
    hasCatch: false,
    hasStickyBanner: checkIfRoutesHasStickyBanner(this.props.location.pathname),
    shouldLoadGTMNoScript:
      isFeatureEnabled(FEATURES_NAMES.FF_SCRIPT_GOOGLE_TAG_MANAGER) &&
      REACT_APP_TENANT === 'ekwateur',
  };

  componentDidMount() {
    const { resetError, initLocation } = this.props;
    const { shouldLoadGTMNoScript } = this.state;
    resetError();
    initLocation();
    localStorage.removeItem(CAR_CONSENT_MODAL);

    if (shouldLoadGTMNoScript) {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://harry.ekwateur.fr/ns.html?id=${REACT_APP_GOOGLE_TAG_MANAGER_KEY}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }
  }

  componentDidCatch(error: unknown) {
    // Display fallback UI
    this.setState({
      error,
      hasCatch: true,
    });
  }

  render() {
    const {
      resetErrorNew,
      errorNew,
      devis,
      subscription,
      eligibility,
      loading,
      setValidationError,
      validationError,
    } = this.props;

    const { hasCatch, error } = this.state;

    return (
      <Route
        render={({ location }) => (
          <>
            <Header />
            {/*
              // @ts-ignore TODO js migration */}
            <Error error={error} />
            <ErrorModal id={MODAL_ERROR_ID} error={errorNew} onClose={resetErrorNew} />
            <LoadingModal />
            <main>
              <AppRouter
                hasCatch={hasCatch}
                location={location}
                eligibility={eligibility}
                subscription={subscription}
                devis={devis}
                loading={loading}
                setValidationError={setValidationError}
                validationError={validationError}
              />
            </main>
            <Footer linkList={footerLinkList} hasStickyBanner={this.state.hasStickyBanner} />
          </>
        )}
      />
    );
  }
}

const mapStateToProps = () =>
  createStructuredSelector({
    loading: makeSelectLoading(),
    validationError: makeSelectValidationError(),
    errorNew: makeSelectErrorNew(),
  });

const mapDispatchToProps = (dispatch: Dispatch, ownprops: AppProps) => ({
  resetError: () => dispatch(resetErrorAction()),
  resetErrorNew: () => dispatch(resetErrorNewAction()),
  initLocation: () => dispatch(setInitLocation(ownprops.location)),
  setValidationError: (hasError: boolean) => dispatch(setValidationErrorAction(hasError)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose<ComponentType>(
  withRouter,
  withConnect,
  WithDevis,
  WithSubscription,
  WithEligibility,
)(App);
